<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理(小程序)</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">主播讲师列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="讲师姓名" class="searchboxItem">
              <span class="itemLabel">讲师姓名:</span>
              <el-input v-model="teacherName" type="text" size="small" placeholder="请输入主播讲师姓名" clearable/>
            </div>
            <div title="讲师身份证号" class="searchboxItem">
              <span class="itemLabel">身份证号:</span>
              <el-input label="身份证号" v-model="idcard" type="text" size="small" placeholder="请输入主播讲师身份证号" clearable/>
            </div>
            <div title="讲师电话" class="searchboxItem">
              <span class="itemLabel">讲师电话:</span>
              <el-input v-model="mobile" type="text" size="small" placeholder="请输入主播讲师电话" clearable/>
            </div>
            <div>
              <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
              <el-button class="bgc-bv" style="margin: 0 10px" round @click="handlAdd()">新增</el-button>
              <!--<el-button class="bgc-bv" style="margin: 0 10px" round @click="synchronization()">从微信同步</el-button>-->
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
                      style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index"/>
              <el-table-column label="老师姓名" align="center" prop="teacherName" show-overflow-tooltip/>
              <el-table-column label="老师身份证号" align="center" prop="idcard" show-overflow-tooltip/>
              <el-table-column label="老师手机号" align="center" prop="mobile" show-overflow-tooltip/>
              <el-table-column label="老师微信号" align="center" prop="username" show-overflow-tooltip/>
              <el-table-column label="老师微信头像" align="center">
                <template width="90" slot-scope="scope">
                  <el-image v-if="scope.row.status == '1'" style="width:50px;height:50px;border:none;"
                            :src="scope.row.headingimg"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="实名认证状态" align="center" prop="status" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.status == '1'" style="color: #00c650">已实名认证</span>
                  <el-image v-else style="width:50px;height:50px;border:none;" :src="scope.row.headingimg"
                            :preview-src-list="codeUrlList"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="添加时间" align="center" prop="createTime" show-overflow-tooltip/>
              <el-table-column label="操作" align="center" width="320px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="editTeacher(scope.row)">编辑
                  </el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="delTeacher(scope.row)">删除
                  </el-button>
                  <el-button v-if="scope.row.status != 1" type="text" size="mini" style="padding: 7px 10px"
                             @click="doAuthentication(scope.row)">实名认证检查
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty"/>
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
      </div>
    </div>
    <el-dialog v-if="dialogVisible" :title="dialogTitle" :visible.sync="dialogVisible" width="50%"
               :before-close="handleClose">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名" prop="teacherName">
            <el-input v-model="ruleForm.teacherName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard">
            <el-input v-model="ruleForm.idcard" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile" size="small"></el-input>
          </el-form-item>
          <el-form-item label="微信号" prop="username">
            <el-input v-model="ruleForm.username" size="small"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleForm.codeUrl" label="" prop="codeUrl">
            <span style="color: red; width: 100%">请主播扫描此二维码进行实名认证后再进行操作</span>
            <img :src="ruleForm.codeUrl" style="width: 130px; height: 130px;">
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="bgc-bv" @click="teacherSave('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "liveTeacherManager",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      editStatus: false,
      closeDialog: false,
      teacherName: "",
      idcard: "",
      mobile: "",
      dialogTitle: "新增直播讲师",
      codeUrlList: [
        "https://res.wx.qq.com/op_res/9rSix1dhHfK4rR049JL0PHJ7TpOvkuZ3mE0z7Ou_Etvjf-w1J_jVX0rZqeStLfwh"
      ],
      ruleForm: {
        teacherId: "",
        teacherName: "",
        idcard: "",
        mobile: "",
        username: "",
        roleList: 2, //默认是主播权限
        codeUrl: ""
      },
      rules: {
        teacherName: [
          {required: true, message: "请输入讲师姓名", trigger: "change"},
        ],
        idcard: [
          {required: true, message: "请输入讲师身份证号码", trigger: "change"},
        ],
        mobile: [
          {required: true, message: "请输入讲师手机号码", trigger: "change"},
        ],
        username: [
          {required: true, message: "请输入讲师微信号", trigger: "change"},
        ],
      }
    };
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        teacherName: this.teacherName,
        idcard: this.idcard,
        mobile: this.mobile
      };
      this.doFetch(
          {
            url: "/miniapp/liveteacher/list",
            params,
            PageNum
          },
          true,
          2
      );
    },
    /* 新增 */
    handlAdd() {
      this.dialogVisible = true;
      this.editStatus = false;
      this.dialogTitle = "新增直播讲师";
      this.ruleForm = {roleList: 2}
    },
    handleClose() {
      this.ruleForm = {roleList: 2}
      this.dialogVisible = false;
      this.editStatus = false;
    },
    editTeacher(row) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑直播讲师";
      this.ruleForm = row;
      this.editStatus = true;
    },
    // 新增直播讲师
    teacherSave(formName) {
      if (this.closeDialog) {
        this.closeDialog = false
        this.handleClose();
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.editStatus)
            this.$post(this.editStatus ? "/miniapp/liveteacher/modify" : "/miniapp/liveteacher/insert",
                this.ruleForm, 3000, true, 2)
                .then((ret) => {
                  this.$message({
                    type: "success",
                    message: ret.message
                  });
                  this.handleClose();
                  this.getData();
                })
                .catch((err) => {
                  if (err.data.data) {
                    console.log(err.data.data)
                    this.ruleForm.codeUrl = err.data.data
                    this.closeDialog = true
                  }
                  this.getData();
                  return;
                });
          }
        });
      }
    },
    // 删除主播讲师
    delTeacher(row) {
      this.$confirm("此操作将永久删除信息，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post("/miniapp/liveteacher/delete/" + row.teacherId, "", 3000, true, 2)
            .then((ret) => {
              this.$message({
                type: "success",
                message: "删除成功"
              });
              this.getData();
            })
            .catch((err) => {
              return;
            });
      });
    },
    // 实名认证
    doAuthentication(row) {
      this.$post("/miniapp/liveteacher/authentication/", row, 3000, true, 2)
          .then((ret) => {
            this.$message({
              type: "success",
              message: "认证成功"
            });
            this.getData();
          })
          .catch((err) => {
            return;
          });
    },
    // synchronization() {
    //   this.$post("/miniapp/liveteacher/synchronization", "", 3000, true, 2)
    //       .then((ret) => {
    //         this.dialogVisible = false;
    //         this.getData();
    //       })
    //       .catch((err) => {
    //         return;
    //       });
    // },

  }
}
</script>

<style scoped>
.unRealName {
  color: red;
}
</style>